import { Injectable } from '@angular/core'
import { Apollo, QueryRef } from 'apollo-angular'
import { DataProxy } from 'apollo-cache'
import { ApolloQueryResult } from 'apollo-client'
import { FetchResult } from 'apollo-link'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { CREATE_MESSAGE } from '../mutations/messages.gql'
import { GET_ALL } from '../queries/messages.gql'
import { ADD } from '../subscriptions/messages.gql'

interface Messages {
  messages: { edges: { id: string; text: string }[] }
}
@Injectable()
export class MessageService {
  public messageRef: QueryRef<any>

  constructor(private apollo: Apollo) {}
  public getAll(): Observable<ApolloQueryResult<any>> {
    this.messageRef = this.apollo.watchQuery({ query: GET_ALL, fetchPolicy: 'network-only' })
    return this.messageRef.valueChanges
  }
  // public async getAll(): Promise<any> {
  //   return await this.apollo.query({
  //     query: GET_ALL,
  //     variables: { CountryCode: 'test', MobileNumber: 'test' },
  //     fetchPolicy: 'network-only',
  //   })
  // }

  public create(text: string) {
    return this.apollo.mutate({
      mutation: CREATE_MESSAGE,
      variables: {
        text,
      },
      update: (proxy: DataProxy, mutationResult: FetchResult<any>) => {},
    })
  }

  public subscribe() {
    return this.apollo.subscribe({ query: ADD })
  }
}
