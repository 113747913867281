import { GraphQLRequest } from 'apollo-link'
import { setContext } from 'apollo-link-context'

function createAuthLink(token: string) {
  const authLink = setContext((operation: GraphQLRequest, prevContext: any) => {
    const jwt: string = token

    if (!jwt) {
      return {}
    } else {
      return {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    }
  })
  return authLink
}

export { createAuthLink }
