import { isPlatformBrowser } from '@angular/common'
import { Inject, NgModule, PLATFORM_ID } from '@angular/core'
import { Apollo, ApolloModule } from 'apollo-angular'
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { from, split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { OperationDefinitionNode } from 'graphql'

import { createAuthLink } from './middlewares/auth'
import { SubscriptionService } from './services/subscription.service'
import { environment } from '../../environments/environment'

@NgModule({
  declarations: [],
  imports: [],
  exports: [ApolloModule, HttpLinkModule],
  providers: [],
})
export class GraphqlModule {
  constructor(
    private readonly apollo: Apollo,
    private readonly httpLink: HttpLink,
    private readonly subscriptionService: SubscriptionService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    const WS_URI = `ws://${environment.apiUrl}${environment.wsPath}`
    const HTTP_URI = `https://${environment.apiUrl}${environment.wsPath}`
    const isBrowser = isPlatformBrowser(platformId)
    // Create an http link:
    const http = this.httpLink.create({
      uri: HTTP_URI,
    })
    const authToken = localStorage.getItem('access-token')
    const authLink = createAuthLink(authToken)
    let networkLink = null

    if (isBrowser) {
      const wsClient = this.subscriptionService.getWSClient(WS_URI, {
        lazy: true,
        // When connectionParams is a function, it gets evaluated before each connection.
        reconnect: true,
        connectionParams: () => {
          return {
            Authorization: `Bearer ${authToken}`,
          }
        },
        reconnectionAttempts: 5,
        connectionCallback: (error: Error[]) => {
          if (error) {
            console.log(error)
          }
        },
        inactivityTimeout: 1000,
      })

      const wsLink = new WebSocketLink(wsClient)
      networkLink = split(
        ({ query }) => {
          const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode
          return kind === 'OperationDefinition' && operation === 'subscription'
        },
        wsLink,
        http,
      )
    }
    this.apollo.create({
      link: from([authLink, isBrowser && networkLink ? networkLink : http]),
      cache: new InMemoryCache(),
      ...(isBrowser
        ? {
            // queries with `forceFetch` enabled will be delayed
            ssrForceFetchDelay: 200,
          }
        : {
            // avoid to run twice queries with `forceFetch` enabled
            ssrMode: true,
          }),
    })
  }
}
