// import { Injectable } from '@angular/core';
// import { HttpClient, HttpHeaders } from '@angular/common/http';

// import { Observable } from 'rxjs';
// import { map } from 'rxjs/operators';
// import { ENV } from '../../@core/env';

// @Injectable({ providedIn: 'root' })
// export class AuthenticationService {
//     public token: string;
//     constructor(private http: HttpClient) {
//         // set token if saved in local storage
//         const currentUser = JSON.parse(localStorage.getItem('currentUser'));
//         this.token = currentUser && currentUser.token;
//     }

//     login(username: string, password: string): Observable<boolean> {
//         const datalogin: any = {
//             grant_type: 'password',
//             client_id: ENV.client_id,
//             client_secret: ENV.client_secret,
//             username: username,
//             password: password
//         };

//         return this.http.post<boolean>(ENV.URL_TOKEN, datalogin).pipe(
//             map((response: any) => {
//                 // login successful if there's a jwt token in the response
//                 const token = response.access_token;
//                 if (token) {
//                     // set token property
//                     this.token = token;

//                     // store username and jwt token in local storage to keep user logged in between page refreshes
//                     localStorage.setItem('currentUser', JSON.stringify({ username: username, token: token }));
//                     localStorage.setItem('refresh_token', response.refresh_token);
//                     // return true to indicate successful login
//                     return true;
//                 } else {
//                     // return false to indicate failed login
//                     return false;
//                 }
//             })
//         );
//     }
//     logoutuser(): Observable<boolean> {
//         // add authorization header with jwt token
//         const httpToken = {
//             headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.token })
//         };
//         return this.http.get(ENV.URL_LOGOUT, httpToken).pipe(
//             map((response: any) => {
//                 if (response) {
//                     // clear token remove user from local storage to log user out
//                     this.token = null;
//                     localStorage.removeItem('currentUser');
//                     localStorage.removeItem('refresh_token');
//                     return true;
//                 } else {
//                     return false;
//                 }
//             }));
//     }

//     logout() {
//         // clear token remove user from local storage to log user out
//         this.token = null;
//         localStorage.removeItem('currentUser');
//         localStorage.removeItem('refresh_token');
//     }
// }
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
	private currentUserSubject: BehaviorSubject<User>;
	public currentUser: Observable<User>;

	constructor(private http: HttpClient) {
		this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
		this.currentUser = this.currentUserSubject.asObservable();
	}

	public get currentUserValue(): User {
		return this.currentUserSubject.value;
	}

	login(username: string, password: string) {
		return this.http.post<any>(`${environment.apiUrl}/users/authenticate`, { username, password })
			.pipe(map(user => {
				// store user details and jwt token in local storage to keep user logged in between page refreshes
				localStorage.setItem('currentUser', JSON.stringify(user));
				this.currentUserSubject.next(user);
				return user;
			}));
	}

	logout() {
		// remove user from local storage to log user out
		localStorage.removeItem('currentUser');
		this.currentUserSubject.next(null);
	}
}
