import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
// plugins
import { MaterialModule } from './@core/material.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
// routing module
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { AuthGuard } from './_guards/auth-guard.service';
import { GraphqlModule } from './_graphql/graphql.module';
import { ServicesModule } from './_graphql/services/services.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true
};
/** config angular i18n **/
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
registerLocaleData(en);

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		MaterialModule,
		PerfectScrollbarModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		NgZorroAntdModule,
		ServicesModule,
    GraphqlModule,
	],
	providers: [
		AuthGuard,
		// AuthenticationService,
		// UserService,
		// UserService,
		MaterialModule,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
		{
			provide: LocationStrategy,
			useClass: HashLocationStrategy
		},
		{ provide: NZ_I18N, useValue: en_US }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
