import gql from 'graphql-tag'

const CREATE_MESSAGE = gql`
  mutation createMessage($text: String!) {
    createMessage(text: $text) {
      id
      text
    }
  }
`

const DELETE_ALL_COMMENT = gql`
  mutation {
    deleteAllComment
  }
`

export { CREATE_MESSAGE, DELETE_ALL_COMMENT }
