import gql from 'graphql-tag'

const ADD = gql`
  subscription {
    messageCreated {
      id
      text
    }
  }
`

export { ADD }
