import gql from 'graphql-tag'

const GET_ALL = gql`
  query {
    messages {
      edges {
        id
        text
      }
    }
  }
`

export { GET_ALL }
